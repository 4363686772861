<template>
    <div class="row min-height">
        <!-- Mobile Navbar Toggle Button -->
        <div class="d-md-none col-12 p-0 mt-1" :class="{ 'd-none': mobileShow, 'd-inline': !mobileShow }">
            <button class="btn close" @click="toggleMobileShow">
                <icon icon="Menu" />
            </button>
        </div>

        <!-- Navbar Vertical -->
        <div :class="showNavbar ? 'col-12 col-md-3 col-xl-2 col-xxl-3' : 'col-12'">
            <div class="d-md-inline" :class="{ 'd-inline': mobileShow, 'd-none': !mobileShow }">
                <NavbarVertical class="navbar" @update-mobile-show="handleMobileShowUpdate" />
            </div>
        </div>

        <!-- Content -->
        <div :class="[
            showNavbar ? 'col-12 col-md-9 col-xl-10 col-xxl-9' : 'col-12',
            mobileShow ? 'd-none' : 'd-inline'
        ]">
            <div class="d-flex justify-content-between upper-content">
                <div v-if="headerTranslation" class="page_header">
                    <h3 class="pt-1">{{ headerTranslation }}</h3>
                </div>
                <div class="language_selector">
                    <LanguageSelector />
                </div>
            </div>
            <div class="content">
                <slot></slot>
            </div>
        </div>
    </div>
</template>

<script>
import NavbarVertical from "@/components/NavbarVertical.vue";
import LanguageSelector from '@/components/LanguageSelector.vue';

export default {
    components: {
        NavbarVertical,
        LanguageSelector
    },
    props: {
        showNavbar: {
            type: Boolean,
            default: false,
            required: true
        }
    },
    data() {
        return {
            mobileShow: false
        };
    },
    computed: {
        headerTranslation() {
            const header = this.$route.meta.header;
            return header ? this.$t(header) : null;
        }
    },
    methods: {
        toggleMobileShow() {
            this.mobileShow = !this.mobileShow;
        },
        handleMobileShowUpdate(show) {
            this.mobileShow = show;
        }
    }
}
</script>


<style lang="scss">
@import "@/scss/main.scss";
@import "@/scss/abstracts/variables";

.navbar {
    max-width: 300px;
}

.min-height {
    min-height: 100vh;
}

.upper-content {
    margin: 1.5% 2% 0% 2%;
}

.language_selector {
    display: flex;
    align-items: center;
}

.content {
    margin: 1.5% 2% 2% 2%;
    font-family: "Inter";
}

.close {
    svg {
        stroke: $white;
        width: 50px;
        height: 50px;
    }
}

.page_header {
    background: conic-gradient(from 90deg at 50% 50%, #B71564 -122.4deg, #890C70 95.4deg, #B71564 237.6deg, #890C70 455.4deg);
    box-shadow: 2px 4px 5px rgba(0, 0, 0, 0.5);
    padding: 10px 35px;
    border-radius: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    text-align: center;
    min-width: 400px;

    h3 {
        margin-top: auto;
        margin-bottom: auto;
        font-size: 32px;
        font-weight: 400;
        letter-spacing: 0.5px;
    }
}

@media (min-width: 1400px) and (max-width: 1700px) {

    .row>.col-3,
    .row>.col-md-4,
    .row>.col-xl-3,
    .row>.col-xxl-2 {
        flex: 0 0 auto;
        width: 25%;
    }

    .row>.col-12,
    .row>.col-md-8,
    .row>.col-xl-9,
    .row>.col-xxl-10 {
        flex: 0 0 auto;
        width: 75%;
    }
}

@media (max-width: 1400px) {
    .navbar {
        max-width: 250px;
    }
}

@media (min-width: 1400px) and (max-width: 1700px) {
    .navbar {
        max-width: 250px;
    }
}
</style>