<template>
    <div class="tag d-flex align-items-center" :style="{ 'color': color, 'background-color': bg }">
        <span class="text mx-auto my-auto">{{ text }}</span>
        <button v-if="showDeleteButton" class="delete-button align-items-center my-0 py-0" @click="deleteTag">
            <span class="x">x</span>
        </button>
    </div>
</template>


<script>
export default {
    props: {
        text: { type: String, required: true },
        color: { type: String, required: true },
        tagId: Number,
        bg: { type: String, required: true },
        showDeleteButton: { type: Boolean, required: false, default: true }
    },
    methods: {
        deleteTag() {
            this.$emit('delete', this.tagId);
        }
    },
}
</script>


<style lang="scss">
.tag {
    font-size: 14px;
    padding: 4px 8px;
    width: 150px;
    border-radius: 10px;
    font-weight: 600;
    position: relative;

    .delete-button {
        background-color: transparent;
        font-size: 20px;
        color: white;
        border: none;
        cursor: pointer;
    }

    .delete-button:hover {
        color: red;
    }
}
</style>
