// common configuration
const config = {
    api: {
        baseUrl: "https://app.salesman.digitalwish.cz/api"
    },
    afterLoginRoute: "companies",
    vueAppUrl: "https://app.salesman.digitalwish.cz",
    MAX_FILE_SIZE_MB: 104857600, // Maximum file size is 100MB
};

if (import.meta.env.VITE_VUE_APP_SERVER == "production") {
}
else if (import.meta.env.VITE_VUE_APP_SERVER == "dev_matej") {
    config["vueAppUrl"] = "http://localhost:10201";
    config["api"]["baseUrl"] = "http://localhost:10200";
}
else if (import.meta.env.VITE_VUE_APP_SERVER == "dev_tomas") {
    config["vueAppUrl"] = "http://localhost:36001";
    config["api"]["baseUrl"] = "http://localhost:36000";
}
else if (import.meta.env.VITE_VUE_APP_SERVER == "dev_kuba") {
    config["vueAppUrl"] = "http://localhost:20001";
    config["api"]["baseUrl"] = "http://localhost:20000";
}

export const CONFIG = config;