<template>
    <div class="container mt-4">
        <div class="row g-3 mb-3">
            <div class="col-md-4">
                <div class="square-block">
                    Počet zaplacených uživatelů/den za minulý měsíc
                </div>
            </div>
            <div class="col-md-4">
                <div class="square-block">
                    Počet zaplacených uživatelů/den za tento měsíc
                </div>
            </div>
            <div class="col-md-4">
                <div class="square-block">
                    Počet klientů/Instutice
                </div>
            </div>
            <div class="col-md-4">
                <div class="square-block">
                    Částka za minulý měsíc
                </div>
            </div>
            <div class="col-md-4">
                <div class="square-block">
                    Částka za tento měsíc
                </div>
            </div>
            <div class="col-md-4">
                <div class="square-block">
                    Počet platících uživatelů/paid_users
                </div>
            </div>
        </div>
        <LineChart />
    </div>
</template>

<script>
    import LineChart from '../components/LineChart.vue';

    export default {
        components: {
            LineChart
        }
    }
</script>

<style scoped>
    .square-block {
        background-color: rgb(36, 35, 35);
        color: white;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 150px;
        border-radius: 10px;
    }
</style>