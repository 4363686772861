<template>
    <CompanyBoxContent :title="$t('company.yourContacts') + ':'">
        <template #data>
            <div v-for="(contact, index) in contacts" :key="contact.id" class="box mb-3">
                <template v-if="!editingContact || contact.id !== editingContactId">
                    <!-- Contact -->
                    <div class="row align-items-center" :class="{ 'mt-2': index > 0 }">
                        <!-- Contact content section -->
                        <div class="col-6 col-md-7 col-xxl-9">
                            <div class="row align-items-center">
                                <div class="col-12 col-xxl-8 full_width">
                                    <div class="row full_width">
                                        <div class="col-12 full_width">
                                            <div class="container-fluid">
                                                <div class="row align-items-center">
                                                    <div class="col-10">
                                                        <span style="font-size: 20px;" class="me-2 fw-bold">
                                                            {{ contact.first_name }} {{ contact.last_name }}
                                                        </span>
                                                    </div>
                                                    <div class="col-2">
                                                        <div class="d-flex justify-content-between">
                                                            <button @click="editContact(contact.id)" class="btn w-50 btn_edit me-2">
                                                                <icon icon="Edit"></icon>
                                                            </button>
                                                            <button @click="deleteContact(contact.id)" class="btn w-50 btn_delete">
                                                                <icon icon="Bin"></icon>
                                                            </button>
                                                        </div>
                                                    </div>
                                                    <div class="col-12">
                                                        <span class="d-block" v-if="contact.phone">
                                                            <strong>Telefon:</strong>&nbsp;<a :href="'tel:' + contact.phone" class="contact_link">{{ contact.phone }}</a></span>
                                                        <span class="d-block" v-if="contact.email">
                                                            <strong>Email:</strong>&nbsp;<a :href="'mailto:' + contact.email" class="contact_link">{{ contact.email }}</a></span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </template>

                <!-- Editing -->
                <template v-else>
                    <div class="mt-3 box contact_box">
                        <div class="form-group mb-3">
                            <div class="d-flex align-items-center">
                                <label for="firstName" class="form-label me-3" style="width: 150px;">
                                    {{ $t('general.writeFirstName') }}:
                                </label>
                                <input type="text" id="firstName" class="form-control" v-model="editingFirstName">
                            </div>
                        </div>
                        <div class="form-group mb-3">
                            <div class="d-flex align-items-center">
                                <label for="lastName" class="form-label me-3" style="width: 150px;">
                                    {{ $t('general.writeLastName') }}:
                                </label>
                                <input type="text" id="lastName" class="form-control" v-model="editingLastName">
                            </div>
                        </div>
                        <div class="form-group mb-3">
                            <div class="d-flex align-items-center">
                                <label for="phone" class="form-label me-3" style="width: 150px;">
                                    {{ $t('general.writePhone') }}:
                                </label>
                                <input type="text" id="phone" class="form-control" v-model="editingPhone">
                            </div>
                        </div>
                        <div class="form-group mb-3">
                            <div class="d-flex align-items-center">
                                <label for="email" class="form-label me-3" style="width: 150px;">
                                    {{ $t('general.writeEmail') }}:
                                </label>
                                <input type="text" id="email" class="form-control" v-model="editingEmail">
                            </div>
                        </div>
                    </div>

                    <div class="d-flex justify-content-between">
                        <button @click="cancelEdit" class="btn w-50 btn_secondary me-2">{{ $t('utils.cancel')
                            }}</button>
                        <button @click="saveEditedContact" class="btn w-50 btn_main">{{ $t('utils.save') }}</button>
                    </div>
                </template>
            </div>

            <!-- Input field for adding new contact -->
            <div class="mt-3 box contact_box">
                <div class="form-group mb-3">
                    <div class="d-flex align-items-center">
                        <label for="firstName" class="form-label me-3" style="width: 150px;">
                            {{ $t('general.writeFirstName') }}:
                        </label>
                        <input type="text" id="firstName" class="form-control" v-model="firstName">
                    </div>
                </div>
                <div class="form-group mb-3">
                    <div class="d-flex align-items-center">
                        <label for="lastName" class="form-label me-3" style="width: 150px;">
                            {{ $t('general.writeLastName') }}:
                        </label>
                        <input type="text" id="lastName" class="form-control" v-model="lastName">
                    </div>
                </div>
                <div class="form-group mb-3">
                    <div class="d-flex align-items-center">
                        <label for="phone" class="form-label me-3" style="width: 150px;">
                            {{ $t('general.writePhone') }}:
                        </label>
                        <input type="text" id="phone" class="form-control" v-model="phone">
                    </div>
                </div>
                <div class="form-group mb-3">
                    <div class="d-flex align-items-center">
                        <label for="email" class="form-label me-3" style="width: 150px;">
                            {{ $t('general.writeEmail') }}:
                        </label>
                        <input type="text" id="email" class="form-control" v-model="email">
                    </div>
                </div>
            </div>
            <div class="mt-3 text-center d-flex justify-content-center">
                <button class="btn btn_main d-flex align-items-center" @click="addContact">
                    <icon icon="Plus" class="me-2"></icon>
                    <span>{{ $t('general.addContact') }}</span>
                </button>
            </div>
        </template>
    </CompanyBoxContent>
</template>

<script>
import CompanyBoxContent from "@/components/CompanyBoxContent.vue";
import UserAvatar from "@/components/CompanyDetail/UserAvatar.vue";

export default {
    props: {
        company: {
            type: Object,
            required: true
        },
        contacts: {
            type: Object,
            required: true
        },
    },
    components: {
        CompanyBoxContent,
        UserAvatar
    },
    data() {
        return {
            firstName: "",
            lastName: "",
            phone: "",
            email: "",
            editingContact: false,
            editingContactId: null,
            editingFirstName: "",
            editingLastName: "",
            editingPhone: "",
            editingEmail: "",
        };
    },
    methods: {
        addContact() {
            if (!this.firstName.trim() || !this.lastName.trim() || !this.phone.trim() || !this.email.trim()) {
                return; // Prevent adding empty contacts
            }
            const formData = {
                firstName: this.firstName,
                lastName: this.lastName,
                phone: this.phone,
                email: this.email
            };

            this.$store.getters.api.post(`/company/${this.$route.params.slug}/company_contact/add`, formData)
                .then(response => {
                    // Refresh company data after adding the contact
                    this.$emit('reload');
                    this.firstName = "";
                    this.lastName = "";
                    this.phone = "";
                    this.email = "";
                })
                .catch(error => {
                    console.error('Error adding contact:', error);
                });
        },
        deleteContact(contactID) {
            this.$store.getters.api.delete(`/company/${this.$route.params.slug}/company_contact/${contactID}/delete`)
            .then(response => {
                this.$emit('reload');
            })
            .catch(error => {
                console.log("Error deleting contact:", error);
            });
        },
        editContact(contactID) {
            this.editingContact = true;
            this.editingContactId = contactID;

            const contact = this.contacts.find(contact => contact.id === contactID);
            this.editingFirstName = contact ? contact.first_name : "";
            this.editingLastName = contact ? contact.last_name : "";
            this.editingPhone = contact ? contact.phone : "";
            this.editingEmail = contact ? contact.email : "";
        },
        saveEditedContact() {
            const formData = {
                first_name: this.editingFirstName,
                last_name: this.editingLastName,
                phone: this.editingPhone,
                email: this.editingEmail,
            };

            this.$store.getters.api.put(`/company/${this.$route.params.slug}/company_contact/${this.editingContactId}/edit`, formData)
            .then(response => {
                this.$emit("reload");
                this.cancelEdit();
            })
            .catch(error => {
                console.error("Error editing contact:", error);
            });
        },
        cancelEdit() {
            this.editingContact = false;
            this.editingContactId = null;
            this.editingFirstName = "";
            this.editingLastName = "";
            this.editingPhone = "";
            this.editingEmail = "";
        },
    }
};
</script>

<style scoped lang="scss">
    @import "@/scss/abstracts/variables";
    @import "@/scss/base/typography.scss";

    .contact_box {
        min-height: 100px;
        max-height: auto;
        overflow-y: auto;
    }

    .btn_edit {
        color: $dark-purple;
        border: none;
        font-weight: 600;

        svg {
            stroke: $dark-purple;
            width: 30px;
            height: 30px;
        }
    }

    .btn_delete {
        color: $dark-purple;
        border: none;
        font-weight: 600;

        svg {
            stroke: $dark-purple;
            width: 30px;
            height: 30px;
        }
    }

    @media screen and (max-width: 1700px) {
        .full_width {
            width: 100%;
        }
    }
</style>