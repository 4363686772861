<template>
    <div class="row institution">
        <div v-if="loading" class="loading-container text-center loading-institution">
            <SpinningWheel class="mb-3 mx-auto"/>
        </div>
        <div class="row w-50 mx-auto">
            <div class="form-group mt-2">
                <label>{{ $t('company.organizationId') }}</label>
                <div  class="row">
                    <div class="col-10">
                        <input type="text" v-model="formData.ico" class="form-control">
                    </div>
                    <div class="col-2">
                        <button class="btn btn_main w-100" @click="find">Najít</button>
                    </div>
                </div>
            </div>
            <div class="form-group">
                <label>{{ $t('company.name') }}</label>
                <input type="text" v-model="formData.name" class="form-control">
            </div>
            <div class="form-group mt-2">
                <label>{{ $t('company.taxId') }}</label>
                <input type="text" v-model="formData.taxId" class="form-control">
            </div>
            <div class="form-group mt-2">
                <label>{{ $t('company.street') }}</label>
                <input type="text" v-model="formData.street" class="form-control">
            </div>
            <div class="form-group mt-2">
                <div class="row">
                    <div class="col-4">
                        <label>{{ $t('company.zipCode') }}</label>
                        <input type="text" v-model="formData.zipCode" class="form-control">
                    </div>
                    <div class="col-8">
                        <label>{{ $t('company.town') }}</label>
                        <input type="text" v-model="formData.city" class="form-control">
                    </div>
                </div>
            </div>
            <div class="form-group mt-2">
                <label>{{ $t('company.billingEmail') }}</label>
                <input type="text" v-model="formData.email" class="form-control">
            </div>
            <div class="form-group mt-2">
                <label>{{ $t('company.plan') }}</label>
                <select class="form-select" v-model="formData.plan">
                    <option :key="null">Žádný</option>
                    <option v-for="plan in plans" :key="plan.id">{{ plan.plan }}</option>
                </select>
            </div>
            <div class="mt-3">
                <button class="btn btn_main" @click="addInstitution()">{{ $t('utils.submit') }}</button>
            </div>
        </div>
    </div>
</template>

<script>
import SpinningWheel from '@/components/SpinningWheel.vue';

export default {
    components: {
        SpinningWheel
    },
    data() {
        return {
            formData: {
                name: null,
                ico: null,
                taxId: null,
                street: null,
                zipCode: null,
                city: null,
                email: null,
                plan: null,
            },
            contacts: [],
            institutions: [],
            plans: [],
            loading: false,
        }
    },
    created() {
        this.loadData()
    },
    methods: {
        loadData() {
            this.$store.getters.api.get("/institutions").then(response => {
                this.institutions = response.data.items;
            })
            this.$store.getters.api.get("/plans").then(response => {
                this.plans = response.data.items;
            })
        },
        addInstitution() {
            this.loading = true;
            this.$store.getters.api.post("/institution/add", this.formData).then(response => {
                this.$router.push({ name: 'AdminInstitution', params: {institution_id: response.data.id} });
            })
            .catch(() => {
                alert("Nelze přidat klienta.");
            })
            .finally(() => {
                this.loading = false;
            })
        },
        find: function () {
            this.loading = true;
            this.$store.getters.api.get("/company/ico/" + this.formData.ico).then(response => {
                var item = response.data.item;
                var address = response.data.address;
                this.formData.name = item.name;
                this.formData.taxId = item.tax_id;
                if (address) {
                    if (address.nazevUlice) {
                        this.formData.street = address.nazevUlice;
                    }
                    else {
                        this.formData.street = address.nazevObce;
                    }
                    if (address.cisloDomovni && address.cisloOrientacni) {
                        this.formData.street += " " + address.cisloDomovni + "/" + address.cisloOrientacni;
                    }
                    else if (address.cisloOrientacni) {
                        this.formData.street += " " + address.cisloOrientacni;
                    }
                    else if (address.cisloDomovni) {
                        this.formData.street += " " + address.cisloDomovni;
                    }
                    this.formData.city = address.nazevObce;
                    this.formData.zipCode = address.psc;
                }
            }).catch(() => {
                alert("Firma nenalezena");
            })
            .finally(() => {
                this.loading = false;
            })
        }
    },
}
</script>

<style scoped>
    .institution {
        position: relative;
    }

    .loading-institution {
        position: absolute;
        top: 0px;
        left: 0px;
        z-index: 10000;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        background-color: rgba(255, 255, 255, 0.7);
        border-radius: 50px;
    }
</style>