<template>
    <!-- Show the content only if the link is active -->
    <div v-if="data.is_active">
        <div v-if="data.institution" class="row justify-content-center align-items-center w-100 vh-100">
            <div v-if="!login && !register" class="header col-12 text-center">
                <h2>{{ $t("general.joinToTheInstitution") }} {{ data.institution.name }}</h2>
            </div>
            <div v-if="!login && !register" class="d-flex justify-content-center align-items-center w-100">
                <div class="buttons d-flex text-center justify-content-center w-50">
                    <div class="text-center">
                        <button @click="showLogin()" class="btn btn_submit py-3 me-4">
                            <span>{{ $t("login.button") }}</span>
                        </button>
                    </div>
                    <div class="text-center">
                        <button @click="showRegister()" class="btn btn_submit py-3">
                            <span>{{ $t("register.button") }}</span>
                        </button>
                    </div>
                </div>
            </div>
            <div>
                <InstitutionAccesLogin v-if="login" :data="data" />
                <InstitutionAccessRegister v-if="register" />
            </div>
        </div>
    </div>
    <div v-else class="row justify-content-center align-items-center w-100 vh-100" style="font-size: 28px;">
        {{ $t("general.linkNotActive") }}
    </div>
</template>

<script>
import InstitutionAccesLogin from "@/components/Institution/InstitutionAccesLogin.vue"
import InstitutionAccessRegister from "@/components/Institution/InstitutionAccessRegister.vue"

export default {
    components: {
        InstitutionAccesLogin,
        InstitutionAccessRegister
    },
    data() {
        return {
            token: this.$route.params.token,
            data: {},
            login: false,
            register: false
        }
    },
    created: function () {
        this.loadData();
    },
    methods: {
        loadData: function () {
            this.$store.getters.api.get(`/institution/user/link/check/${this.token}`).then(function (response) {
                this.data = response.data.item;
            }.bind(this));
        },
        showLogin: function () {
            this.login = true
        },
        showRegister: function () {
            this.register = true
        }
    }
}
</script>

<style scoped lang="scss">
@import "@/scss/abstracts/variables";
@import "@/scss/base/typography.scss";
</style>