<template>
    <div class="chart-wrapper">
        <div class="timeline-buttons mb-3 text-center">
            <button class="btn btn_main me-2" @click="updateData('one_month')" :class="{active: selection==='one_month'}">1 měsíc</button>
            <button class="btn btn_main me-2" @click="updateData('six_months')" :class="{active: selection==='six_months'}">6 měsíců</button>
            <button class="btn btn_main me-2" @click="updateData('one_year')" :class="{active: selection==='one_year'}">1 rok</button>
            <button class="btn btn_main me-2" @click="updateData('all')" :class="{active: selection==='all'}">Vše</button>
        </div>
        <apexchart ref="chart" :options="chartOptions" :series="series"></apexchart>
    </div>
</template>

<script>
    import VueApexCharts from "vue3-apexcharts";

    export default {
        components: {
            apexchart: VueApexCharts
        },
        props: {
            chartData: {
                type: Array,
                required: true
            }
        },
        data() {
            return {
                selection: "", // Aktuální časový výběr (např. "one_month", "six_months")
                chartOptions: {
                    markers: { // Nastavení markerů na lince grafu
                        shape: "circle", // ("circle", "square")
                        size: 6,
                        hover: {
                            size: 10,
                        }
                    },
                    colors: ["#87218D"], // Barva linky grafu
                    chart: {
                        id: "area-datetime",
                        type: "area", // Typ grafu (např. "area", "line", "bar")
                        height: 300,
                        zoom: {
                            autoScaleYaxis: true // Automaticky přizpůsobí osu Y při zoomování
                        },
                        toolbar: {
                            show: false, // Skryje toolbar grafu (lupa, reset, export atd.)
                        },
                        background: "#FFFFFF" // Barva pozadí grafu
                    },
                    xaxis: {
                        categories: [],
                        type: "datetime", // Typ osy X jako časová osa
                        labels: {
                            formatter: function (value) {
                                // Formátování dat na ose X na české měsíce a rok
                                const date = new Date(value);
                                const months = [
                                    "Leden", "Únor", "Březen", "Duben", "Květen", "Červen",
                                    "Červenec", "Srpen", "Září", "Říjen", "Listopad", "Prosinec"
                                ];
                                return `${months[date.getMonth()]} ${date.getFullYear()}`;
                            }
                        }
                    },
                    title: { // Nadpis grafu
                        text: "Platící uživatelé",
                        align: "center",
                        style: {
                            fontSize: "20px",
                            color: "black"
                        }
                    },
                    tooltip: { // Tooltipy, které se zobrazují při najetí myší na data
                        enabled: true,
                        theme: "dark", // ("light", "dark")
                        x: {
                            formatter: function (value) {
                                // Formátování data (den.měsíc.rok)
                                const date = new Date(value);
                                return `${date.getDate()}.${date.getMonth() + 1}.${date.getFullYear()}`;
                            }
                        },
                    },
                    dataLabels: { // Popisky hodnot (trvalé zobrazení čísel u datových bodů na lince grafu)
                        enabled: false
                    }
                },
                series: [ // Datová série (hodnoty, které graf vykresluje)
                    {
                        name: "Platící uživatelé", // Název datové série
                        data: [] // Data pro graf (pole ve formátu [timestamp, hodnota])
                    }
                ]
            };
        },
        methods: {
            updateData(timeline) {
                this.selection = timeline
                const now = new Date();
                let start, end;

                switch (timeline) {
                    case "one_month":
                        start = new Date(now.getFullYear(), now.getMonth() - 1, now.getDate()).getTime();
                        end = now.getTime();
                        break;
                    case "six_months":
                        start = new Date(now.getFullYear(), now.getMonth() - 6, now.getDate()).getTime();
                        end = now.getTime();
                        break;
                    case "one_year":
                        start = new Date(now.getFullYear() - 1, now.getMonth(), now.getDate()).getTime();
                        end = now.getTime();
                        break;
                    case "all":
                        start = new Date("2025-01-01").getTime();
                        end = now.getTime();
                        break;
                    default:
                        return;
                }

                // Zoom for the selected time period
                this.$refs.chart.zoomX(start, end);
            }
        },
        watch: {
            chartData: {
            immediate: true,
                handler(newData) {
                    this.series[0].data = newData.map(item => [new Date(item.date).getTime(), item.paidUsers]);
                }
            }
        }
    }
</script>

<style lang="scss" scoped>
    .chart-wrapper {
        padding: 15px;
        background-color: white;
        border-radius: 10px;
    }
    .timeline-buttons button.active {
        color: #550459;
        background-color: white;
        border-color: #550459;
    }
</style>