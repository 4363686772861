<template>
    <CompanyBoxContent>
        <!-- Basic info -->
        <template #title>
            <div class="row">
                <div class="col-6 title">
                    <h3>{{ $t('company.basicInfo') }}</h3>
                </div>
                <div class="col-6 title">
                    <h3>{{ $t('company.map') }}</h3>
                </div>
            </div>
        </template>

        <template #data>
            <div class="row">
                <div class="col-6">
                    <div v-if="company.name" class="row">
                        <span class="col-4">{{ $t('company.name') }}:</span>
                        <span class="fw-bold col-8">{{ company.name }}</span>
                    </div>
                    <span v-if="company.organization_id" class="row">
                        <span class="col-4">{{ $t("company.organizationId") }}:</span>
                        <span class="col-8">{{ company.organization_id }}</span>
                    </span>
                    <span v-if="company.tax_id" class="row">
                        <span class="col-4">{{ $t("company.taxId") }}:</span>
                        <span class="col-8">{{ company.tax_id }}</span>
                    </span>
                    <span v-if="company.address" class="row">
                        <span class="col-4">{{ $t("company.address") }}:</span>
                        <span class="col-8">{{ company.address }}</span>
                    </span>
                    <span v-if="company.creation_date" class="row">
                        <span class="col-4">{{ $t("company.creationDate") }}: </span>
                        <i18n-d class="col-8" tag="span" :value="new Date(company.creation_date)"></i18n-d>
                    </span>
                    <span v-if="company.expiry_date" class="row">
                        <span class="col-4">{{ $t("company.expiryDate") }}: </span>
                        <i18n-d class="col-8" tag="span" :value="new Date(company.expiry_date)"></i18n-d>
                    </span>
                    <span v-if="company.legal_form" class="row">
                        <span class="col-4">{{ $t("company.legalForm") }}:</span>
                        <span class="col-8">{{ company.legal_form.name }}</span>
                    </span>
                    <span v-if="company.employees" class="row">
                        <span class="col-4">{{ $t("company.employees") }}:</span>
                        <span class="col-8">{{ company.employees.name }}</span>
                    </span>
                </div>
                <div v-if="companyAddress && companyAddress.length > 0" class="col-6">
                    <div v-if="existCompanyResidenceMap()"
                        class="d-flex align-items-center justify-content-center">
                        <div class="" id="map">
                            <Map :companyAddress="companyAddress" />
                        </div>
                    </div>
                    <div v-else class="d-flex box align-items-stretch h-100 justify-content-center">
                        <div class="text-center align-self-center w-100">
                            <span style="font-size: 18px; font-weight: 500;">{{ $t('company.mapNotAvailible') }}</span>
                        </div>
                    </div>
                </div>
            </div>
        </template>
    </CompanyBoxContent>
</template>

<script>
import CompanyBoxContent from "@/components/CompanyBoxContent.vue"
import Map from '@/components/CompanyDetail/Map.vue';

export default {
    props: {
        company: {
            type: Object,
            required: true
        },
        companyAddress: {
            type: Array,
            required: true
        }
    },
    components: {
        CompanyBoxContent,
        Map,
    },
    methods: {
        existCompanyResidenceMap() {
            if (this.companyAddress) {
                return this.companyAddress.some(item => item.residence === true);

            }
            return false;
        }
    },
}
</script>

<style scoped lang="scss">
@import "@/scss/abstracts/variables";
@import "@/scss/base/typography.scss";

.title {
    padding: 0 0 5px 20px;

    h3 {
        font-weight: 700;
        font-size: 24px;
    }
}

#map {
    height: 220px;
    width: 100%;
    border-radius: 35px;
}
</style>