import { createRouter, createWebHistory } from 'vue-router';

import store from "@/store.js"

import Login from '@/views/Login.vue';
import Companies from '@/views/Companies.vue';
import Company from '@/views/Company.vue';
import Profile from '@/views/Profile.vue';
import Institution from '@/views/Institution.vue';
import InstitutionAccess from '@/views/InstitutionAccess.vue';
import NoInstitution from '@/views/NoInstitution.vue';
import RegisterInstitution from '@/views/RegisterInstitution.vue';
import RegisterPerson from '@/views/RegisterPerson.vue';
import PasswordReset from '@/views/PasswordReset.vue';
import PasswordResetConfirm from '@/views/PasswordResetConfirm.vue';
import Admin from '@/views/Admin.vue';
import AdminInstitution from '@/views/AdminInstitution.vue';
import AdminAddInstitution from '@/views/AdminAddInstitution.vue';
import BussinessTermsActual from '@/views/BussinessTermsActual.vue';
import BussinessTermsHistory from '@/views/BussinessTermsHistory.vue';
import PersonalDataProtection from '@/views/PersonalDataProtection.vue';
import AdminGeneralStats from './views/AdminGeneralStats.vue';
import AdminSalesStats from './views/AdminSalesStats.vue';


const routes = [
    {
        path: '/',
        name: 'login',
        component: Login,
        meta: {
            title: "titles.login",
            navbar: false,
            admin: false
        }
    },
    {
        path: '/logout/',
        name: 'logout',
        async beforeEnter(to, from, next) {
            // after logout redirect to login
            await store.getters.api.get("/auth/logout");
            store.commit("saveUserProfile", {});
            next({ name: "login" });
        }
    },
    {
        path: '/admin/',
        name: 'Admin',
        component: Admin,
        meta: {
            title: "titles.admin",
            header: "titles.admin",
            navbar: true,
            admin: true
        }
    },
    {
        path: '/admin/institution/:institution_id',
        name: 'AdminInstitution',
        component: AdminInstitution,
        meta: {
            title: "titles.adminInstitution",
            header: "titles.adminInstitution",
            navbar: true,
            admin: true
        }
    },
    {
        path: '/admin/institution/add',
        name: 'AdminAddInstitution',
        component: AdminAddInstitution,
        meta: {
            title: "titles.addInstitution",
            header: "titles.addInstitution",
            navbar: true,
            admin: true
        }
    },
    {
        path: '/admin/general-stats',
        name: 'AdminGeneralStats',
        component: AdminGeneralStats,
        meta: {
            title: "titles.generalStats",
            header: "titles.generalStats",
            navbar: true,
            admin: true
        }
    },
    {
        path: '/admin/sales-stats',
        name: 'AdminSalesStats',
        component: AdminSalesStats,
        meta: {
            title: 'titles.salesStats',
            header: 'titles.salesStats',
            navbar: true,
            admin: true
        }
    },
    {
        path: '/access/:token',
        name: 'InstitutionAccess',
        component: InstitutionAccess,
        meta: {
            title: "titles.joinToTheInstitution",
            navbar: false,
            admin: false
        }
    },
    {
        path: '/companies',
        name: 'companies',
        component: Companies,
        meta: {
            title: "titles.companies",
            header: "company.companySearch",
            navbar: true,
            admin: false
        }
    },
    {
        path: '/company/:slug',
        name: 'company',
        component: Company,
        meta: {
            title: "titles.company",
            navbar: true,
            header: "titles.company",
            admin: false
        }
    },
    {
        path: '/profile',
        name: 'profile',
        component: Profile,
        meta: {
            title: "titles.profile",
            header: "user.myProfile",
            navbar: true,
            admin: false
        }
    },
    {
        path: '/institution/:institution_id',
        name: 'institution',
        component: Institution,
        meta: {
            title: "titles.institution",
            navbar: true,
            admin: false,
            header: "navbar.institution",
        },
    },
    {
        path: '/institution/register',
        name: 'RegisterInstitution',
        component: RegisterInstitution,
        meta: {
            title: "titles.registerInstitution",
            navbar: true,
            admin: false,
        }
    },
    {
        path: '/person/register',
        name: 'RegisterPerson',
        component: RegisterPerson,
        meta: {
            title: "titles.registerPerson",
            navbar: true,
            admin: false,
        }
    },
    {
        path: '/institution/none',
        name: 'NoInstitution',
        component: NoInstitution,
        meta: {
            title: "titles.noInstitution",
            navbar: true,
            admin: false,
        }
    },
    {
        path: '/reset-password',
        name: 'PasswordReset',
        component: PasswordReset,
        meta: {
            title: "titles.passwordReset",
            navbar: false,
            admin: false,
        }
    },
    {
        path: '/reset-password-confirm',
        name: 'PasswordResetConfirm',
        component: PasswordResetConfirm,
        props: route => ({
            uid: route.query.uid,
            token: route.query.token
        }),
        meta: {
            title: "titles.passwordResetConfirm",
            navbar: false,
            admin: false,
        }
    },
    {
        path: '/gdpr',
        name: 'PersonalDataProtection',
        component: PersonalDataProtection,
        meta: {
            title: "titles.personalDataProtection",
            navbar: false,
            admin: false,
        }
    },
    {
        path: '/terms',
        name: 'BussinessTermsActual',
        component: BussinessTermsActual,
        meta: {
            title: "titles.bussinessTermsActual",
            navbar: false,
            admin: false,
        }
    },
    {
        path: '/terms/history',
        name: 'BussinessTermsHistory',
        component: BussinessTermsHistory,
        meta: {
            title: "titles.BussinessTermsHistory",
            navbar: false,
            admin: false,
        }
    },
]

const router = createRouter({
    history: createWebHistory(),
    routes: routes
})

router.beforeEach((to, from, next) => {
    const openRoutes = ['login', 'logout', 'InstitutionAccess', 'PasswordReset', 'PasswordResetConfirm', 'BussinessTermsActual', 'BussinessTermsHistory',
        'PersonalDataProtection'];

    if (store.getters.isLoggedIn || openRoutes.includes(to.name)) {
        next();
    } else {
        next({ name: 'login', query: { path: to.path } });
    }
});

export default router;