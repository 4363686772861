<template>
    <div class="row justify-content-center">
        <div class="col-10 institutions">
            <div class="row block w-100">
                <div class="d-flex align-items-center justify-content-between">
                    <h3 class="me-3">{{ $t('admin.institutions')
                        }}</h3>
                    <router-link :to="{ name: 'AdminAddInstitution' }" class="btn btn_main">
                        {{ $t("utils.addInstitution") }}
                    </router-link>
                </div>
                <div class="mt-3">
                    <input v-model="search" class="form-control" :placeholder="$t('admin.search')">
                </div>
                <div v-if="institutions">
                    <div v-for="institution in filteredInstitutions" :key="institution.id" class="mt-3 row">
                        <router-link class="btn institution_box"
                            :to="{ name: 'AdminInstitution', params: { institution_id: institution.id } }">
                            <div class="d-flex">
                                <div class="col-8">
                                    <div class="text-start">
                                        <span class="fw-bold">{{ institution.name }}</span>
                                    </div>
                                    <div class="text-start">
                                        <div class="me-2">
                                            <span class="pe-2">{{ $t('company.plan') }}:</span>
                                            <span v-if="institution.plan">{{ institution.plan.plan }}</span>
                                            <span v-else>-</span>
                                        </div>
                                        <div>
                                            <span class="pe-2">{{ $t('institution.invoicing.paidTo') }}:</span>
                                            <i18n-d v-if="institution.paid_to" tag="span"
                                                :value="new Date(institution.paid_to)"></i18n-d>
                                            <span v-else>-</span>
                                        </div>
                                    </div>
                                    <div class="text-start">
                                        <span class="pe-2">Počet zaplacených uživatelů:</span>
                                        <span v-if="institution.paid_users">{{ institution.paid_users }}</span>
                                        <span v-else>-</span>
                                    </div>
                                </div>
                                <div class="col-4 d-flex justify-content-center align-items-center">
                                    <UserAvatar v-if="institution.sales_representative" :user="institution.sales_representative"/>
                                    <span v-else>Nemá obchodního zástupce</span>
                                </div>
                            </div>
                        </router-link>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import UserAvatar from "../components/CompanyDetail/UserAvatar.vue";

export default {
    name: 'Admin',
    components: {
        UserAvatar
    },
    data() {
        return {
            institutions: [],
            search: '',
        }
    },
    created() {
        this.loadData()
    },
    computed: {
        filteredInstitutions() {
            // Return institutions that match the search term (case insensitive)
            return this.institutions.filter(institution =>
                institution.name.toLowerCase().includes(this.search.toLowerCase())
            );
        }
    },
    methods: {
        loadData() {
            this.$store.getters.api.get("/institutions").then(response => {
                this.institutions = response.data.items;
            })
        },
    }
}
</script>

<style lang="scss" scoped>
@import "@/scss/abstracts/variables";

.institution_box {
    background-color: $white;
    color: $black;
}
</style>