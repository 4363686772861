<template>
    <p>
        Smyslem tohoto sdělení je poskytnout informace zejména o tom, jaké osobní údaje shromažďujeme, jak s nimi
        nakládáme, z jakých zdrojů je získáváme, k jakým účelům je využíváme, komu je smíme poskytnout, kde můžete
        získat informace o Vašich osobních údajích a jaká jsou Vaše individuální práva v oblasti ochrany osobních údajů.
        Při zpracování osobních údajů se řídíme obecně závaznými právními předpisy a zpracování osobních údajů probíhá
        vždy pouze v rozsahu daném konkrétní službou nebo účelem zpracování.
    </p>

    <hr />

    <h2>Obecné informace</h2>
    <p>S ohledem na naši podnikatelskou činnost jsme povinni zpracovávat některé osobní údaje, a to zejména pro účely
        plnění zákonných a smluvních povinností. V tomto ohledu bychom bez poskytnutí osobních údajů nemohli naše služby
        poskytnout.</p>

    <h2>Zásady zpracování osobních údajů</h2>
    <p>Při zpracování osobních údajů ctíme a respektujeme nejvyšší možné standardy ochrany osobních údajů a dodržujeme
        zejména následující zásady:</p>
    <ul class="list-group mb-4">
        <li class="list-group-item">Osobní údaje vždy zpracováváme pro jasně a srozumitelně stanovený účel, stanovenými
            prostředky, stanoveným způsobem, a pouze po dobu, která je nezbytná vzhledem k účelům jejich zpracování;
            zpracováváme pouze přesné osobní údaje klientů a máme zajištěno, že jejich zpracování odpovídá stanoveným
            účelům a je nezbytné pro naplnění těchto účelů;
        </li>
        <li class="list-group-item">Osobní údaje chráníme jako utajované informace; proto zpracováváme osobní údaje
            způsobem, který zajišťuje nejvyšší možnou bezpečnost těchto údajů a který zabraňuje jakémukoliv
            neoprávněnému nebo nahodilému přístupu k osobním údajům, k jejich změně, zničení či ztrátě, neoprávněným
            přenosům, k jejich jinému neoprávněnému zpracování, jakož i k jinému zneužití
        </li>
        <li class="list-group-item">Vždy srozumitelně informujeme o zpracování osobních údajů a o nárocích na přesné a
            úplné informace o okolnostech tohoto zpracování, jakož i o dalších souvisejících právech;</li>
        <li class="list-group-item">Nastavili jsme a dodržujeme odpovídající technická a organizační opatření, aby byla
            zajištěna úroveň zabezpečení odpovídající všem možným rizikům; veškeré osoby, které přicházejí do styku s
            osobními údaji, mají povinnost dodržovat mlčenlivost o informacích získaných v souvislosti se zpracováním
            těchto údajů.</li>
    </ul>

    <h2>Účely zpracovávání a právní základ pro zpracování</h2>
    <br>
    <h3>1. Zpracování osobních údajů bez souhlasu</h3>
    <p>Jde většinou o situace, kdy jste nám povinni předat určité osobní údaje jako podmínku toho, abychom Vám mohli
        poskytnout svoje služby, případně kdy jsme oprávněni zpracovávat Vaše osobní údaje získané jiným způsobem.</p>
    <p>Osobní údaje jsme oprávněni ze zákona zpracovávat bez Vašeho souhlasu pro následující účely dodržení našich
        právních povinností, zejména:</p>
    <div>
        <h4>1) Předcházení vzniku škod na majetku naší společnosti</h4>
        <ul class="list-group mb-4">
            <li class="list-group-item">prevence podvodného jednání, kterému může být naše společnost vystavena;
            </li>
            <li class="list-group-item">splnění případné povinnosti při identifikaci a kontrole klienta dle zákona o
                některých opatřeních proti legalizaci výnosů z trestné činnosti a financování terorismu.
            </li>
        </ul>
    </div>
    <div>
        <h4>2) Uzavření nebo plnění smlouvy s Vámi</h4>
        <ul class="list-group mb-4">
            <li class="list-group-item">zejména faktická realizace smluvního vztahu nebo jiné plnění smlouvy mezi Vámi a
                naší společností. Osobní údaje jsou potřebné také k tomu, aby bylo možné smluvní vztah uskutečnit bez
                nepřiměřených právních rizik, včetně jednání o uzavření nebo změně smlouvy s Vámi. <br></li>
        </ul>
    </div>
    <div>
        <h4>3) Ochrana práv a právem chráněných zájmů, zejména pro</h4>
        <ul class="list-group mb-4">
            <li class="list-group-item"> naši společnost, vymáhání pohledávek, realizace zajištění nebo jiného uplatnění
                pohledávek, rozvoj a vývoj poskytovaných služeb;</li>
            <li class="list-group-item"> jednání se zájemci o postoupení pohledávky naší společnosti za klientem nebo o
                jiné formě převodu či přechodu pohledávky, včetně související realizace, a další navazujících jednání se
                třetími osobami, zejména informování poskytovatelů souvisejících zajištění apod.;</li>
            <li class="list-group-item"> řešení veškeré sporné agendy, zejména pro účely vedení soudních či jiných
                sporů.</li>
        </ul>
    </div>
    <div>
        <h4>4) Naše oprávněné zájmy</h4>
        <ul class="list-group mb-4">
            <li class="list-group-item">Jde zejména o situaci, kde existuje smluvní zákaznický vztah mezi Vámi a naší
                společností.
            </li>
        </ul>
    </div>
    <h3>2. Zpracování osobních údajů s Vaším souhlasem</h3>
    <div>
        <p>
            Jde zpravidla o situace, kdy dobrovolně souhlasíte s tím, abychom zpracovávali Vaše osobní údaje. Na základě
            Vašeho souhlasu zpracovává naše společnosti Vaše osobní údaje pro následujíc účely:
            <br />
        <ul class="list-group mb-4">
            <li class="list-group-item">Péče o klienty; tedy aktivity, které nepředstavují plnění smlouvy či jiný
                zákonný rámec zpracování osobních údajů a které zahrnují následujíc činnosti:</li>
            <li class="list-group-item">· získávání zpětné vazby ohledně produktů a služeb;</li>
            <li class="list-group-item">· monitoring chování klientů v Aplikaci v souvislosti s nabízenými službami</li>
        </ul>
        Poskytnutí osobních údajů pro účely péče o Vás jsou naším smluvním požadavkem a jejich neposkytnutí může ve
        svém důsledku vést k neuzavření smlouvy nebo neposkytnutí adekvátní péče.
        </p>
    </div>
    <h3>3. Rozsah zpracovávaných osobních údajů klientů</h3>
    <div>
        <p>
            Vaše osobní údaje zpracováváme v rozsahu nezbytném pro naplnění výše uvedených účelů. Zpracováváme kontaktní
            údaje :IP adresa, useragent (technické informace o prohlížeči a systému), cookies, pojíme to s konkrétním
            uživatelem, prohlížeč, operační systém a typ zařízení a kdy, kontaktní adresy, telefonní čísla, e-mailové či
            jiné obdobné kontaktní údaje) a identifikační údaje (jméno, příjmení,; dále také IČ a DIČ v případě
            subjektu).
        </p>
    </div>
    <h3>4. Způsob zpracování osobních údajů</h3>
    <div>
        <p>
            Způsob, kterým naše společnost zpracovává Vaše osobní údaje, zahrnuje manuální i automatizované zpracování v
            informačních systémech naší společnosti.
        </p>
        <p>
            Vaše osobní údaje zpracovávají především zaměstnanci naší společnosti a v potřebném rozsahu také třetí
            osoby. Před jakýmkoliv předáním Vašich osobních údajů třetí osobě vždy s touto osobou uzavřeme písemnou
            smlouvu, která obsahuje stejné záruky pro zpracování osobních údajů, jaké v souladu se svými zákonnými
            povinnostmi dodržuje sama naše společnost.
        </p>
    </div>
    <h3>5. Příjemci osobních údajů</h3>
    <div>
        <p>
            Vaše osobní údaje klientů jsou zpřístupněny zejména zaměstnancům naší společnosti v souvislosti s plněním
            jejich pracovních povinností, při kterých je nutné nakládat s osobními údaji klientů, pouze však v rozsahu,
            který je nezbytný a při dodržení veškerých bezpečnostních opatření.
            Vedle toho jsou Vaše osobní údaje předávány třetím osobám, které se podílejí na zpracování osobních údajů
            klientů naší společnosti, případně jim tyto osobní údaje mohou být zpřístupněny z jiného důvodu v souladu se
            zákonem. </p>
        <p>
            K předávání dochází zejména směrem k:
        </p>
        <ul class="list-group mb-4">
            <li class="list-group-item">· naší advokátní kanceláři;</li>
            <li class="list-group-item">· poskytovateli účetních, daňových a mzdových služeb;</li>
            <li class="list-group-item">· zpracovatelům, kteří nám poskytují serverové, webové, cloudové a IT služby.
            </li>
        </ul>
        <p>
            Před jakýmkoliv předáním Vašich osobních údajů třetí osobě vždy s touto osobou uzavřeme písemnou smlouvu, ve
            které upravíme zpracování osobních údajů tak, aby obsahovala stejné záruky pro zpracování osobních údajů,
            jaké v souladu se svými zákonnými povinnostmi dodržuje sama naše společnost.
        </p>
    </div>
    <h3>6. Předávání osobních údajů do zahraničí</h3>
    <div>
        <p>
            Vzhledem ke globalizaci digitálních služeb a pro lepší fungování námi poskytovaných služeb využíváme různá
            technologická řešení jiných společností, se kterými je spolupráce vždy ošetřená uzavřením příslušné dohody o
            zpracování osobních údajů či tzv. standardní smluvní doložky, pokud má partner sídlo mimo území EU.
        </p>
    </div>
    <h3>7. Doba zpracování osobních údajů</h3>
    <div>
        <p>
            Osobní údaje klientů zpracovává naše společnost pouze po dobu, která je nezbytná vzhledem k účelům jejich
            zpracování. Průběžně posuzujeme, jestli nadále trvá potřeba zpracovávat určité osobní údaje potřebné pro
            určitý účel. Pokud zjistíme, že již nejsou potřebné pro žádný z účelů, pro které byly zpracovávány, údaje
            likvidujeme. Interně jsme však již ve vztahu k určitým účelům zpracování osobních údajů vyhodnotili obvyklou
            dobu využitelnosti osobních údajů, při jejímž uplynutí zvlášť pečlivě posuzujeme potřebu zpracovávat
            příslušné osobní údaje pro daný účel. V té souvislosti zároveň platí, že osobní údaje zpracovávané pro
            účely:
        </p>
        <p>
            K předávání dochází zejména směrem k:
        </p>
        <ul class="list-group mb-4">
            <li class="list-group-item">· plnění smlouvy; zpracováváme po dobu trvání smluvního vztahu s klientem; a
                následující 2 měsíce.</li>
            <li class="list-group-item">· nabízení produktů a služeb; zpracováváme po dobu trvání smluvního vztahu a
                následně po dobu dvou let.</li>
            <li class="list-group-item">· péče o klienty; zpracováváme po dobu trvání smluvního vztahu s klientem;
            </li>
            <li class="list-group-item">· účetní operace; zpracováváme po dobu trvání smluvního vztahu s klientem a
                následně po dobu zákonem daných dob ukládání účetních dokumentů.
            </li>
        </ul>
        <p>
            V případě soudních sporů uchováváme dle potřeb daných státních institucí (PČR, soudy).
        </p>
    </div>
    <h3>8. Právo odvolat souhlas</h3>
    <div>
        <p>
            Souhlas se zpracováním svých osobních údajů nejste naší společnosti povinni udělit a zároveň jste oprávněni
            tento svůj souhlas odvolat. Připomínáme zde, že některé osobní údaje jsme oprávněni zpracovávat k určitým
            účelům také bez Vašeho souhlasu. Pokud v takovém případě odvoláte svůj souhlas, ukončíme zpracování
            příslušných osobních údajů k účelům vyžadujícím příslušný souhlas, avšak můžeme být oprávněni, nebo dokonce
            povinni, tytéž osobní údaje nadále zpracovávat k účelům jiným.
        </p>
        <p>
            V případě, že si budete přát odvolat svůj souhlas se zpracováním osobních údajů, obraťte se na nás na
            e-mailu marek@digitalwish.cz.
        </p>
    </div>
    <h3>9. Zdroje osobních údajů</h3>
    <div>
        <p>
            Osobní údaje klientů získáváme zejména:
        </p>
        <ul class="list-group mb-4">
            <li class="list-group-item">· přímo od klientů;</li>
            <li class="list-group-item">· z veřejně dostupných zdrojů
            </li>
            <li class="list-group-item">· od potenciálních zájemců o služby naší společnosti v rámci marketingových akcí
                a kampaní;
            </li>
            <li class="list-group-item">· z vlastní činnosti, a to zpracováváním a vyhodnocováním ostatních osobních
                údajů klientů.
            </li>
        </ul>
    </div>
    <h3>10. Vaše práva v souvislosti se zpracováním Vašich osobních údajů</h3>
    <div>
        <p>
            Všechna Vaše práva můžete uplatnit na e-mailu marek@digitalwish.cz s tím, že zároveň můžete podat stížnost u
            dozorového úřadu, kterým je Úřad pro ochranu osobních údajů (www.uoou.cz).
        </p>
        <ul class="list-group mb-4">
            <li class="list-group-item">· Právo na přístup znamená, že si kdykoliv můžete požádat o naše potvrzení, zda
                osobní údaje, které se Vás týkají, jsou či nejsou zpracovávány, a pokud jsou, pak za jakými účely, v
                jakém rozsahu, komu jsou zpřístupněny, jak dlouho je budeme zpracovávat, zda máte právo na opravu,
                výmaz, omezení zpracování či vznést námitku, odkud jsme osobní údaje získali a zda dochází na základě
                zpracování Vašich osobních údajů k automatickému rozhodování, včetně případného profilování. Také máte
                právo získat kopii Vašich osobních údajů, přičemž první poskytnutí je bezplatné, za další poskytnutí pak
                můžeme požadovat přiměřenou náhradu administrativních nákladů.</li>
            <li class="list-group-item">· Právo na opravu znamená, že nás kdykoliv můžete požádat o opravu či doplnění
                Vašich osobních údajů, pokud by byly nepřesné či neúplné.</li>
            <li class="list-group-item">· Právo na výmaz znamená, že musíme vymazat Vaše osobní údaje v případě, že už
                nejsou potřebné pro účely, pro které byly shromážděny nebo jinak zpracovány, zpracování je protiprávní,
                vznesete námitky proti zpracování a neexistují žádné převažující oprávněné důvody pro zpracování, nebo
                nám to ukládá zákonná povinnost.
            </li>
            <li class="list-group-item">· Právo na omezení zpracování znamená, že dokud nevyřešíme jakékoliv sporné
                otázky ohledně zpracování Vašich osobních údajů, musíme omezit zpracování Vašich osobních údajů tak, že
                tyto můžeme mít pouze uloženy a případně je můžeme použít z důvodu určení, výkonu nebo obhajoby právních
                nároků.
            </li>
            <li class="list-group-item">· Právo vznést námitku znamená, že můžete vznést námitku proti zpracování Vašich
                osobních údajů, které zpracováváme pro účely přímého marketingu nebo z důvodu oprávněného zájmu. Pokud
                vznesete námitku proti zpracování pro účely přímého marketingu, nebudou již Vaše osobní údaje pro tyto
                účely zpracovávány.
            </li>
        </ul>
        <p>
            V případě soudních sporů uchováváme dle potřeb daných státních institucí (PČR, soudy).
        </p>
    </div>
    <h3>11. Pověřenec pro ochranu osobních údajů</h3>
    <div>
        <p>
            Nařízení nám také ukládá povinnost jmenovat osobu, která se o správné zpracování osobních údajů v naší
            společnosti stará. Na pověřence se můžete obracet ve všech záležitostech souvisejících se zpracováním vašich
            osobních údajů nebo výkonem vašich práv podle GDPR. Pověřencem pro ochranu osobních údajů je Matěj Marek,
            kontakt tel. 736 109 281, email: marek@digitalwish.cz.</p>
    </div>
    <p style="font-weight: bold">
        Tyto uživatelské podmínky nabývají platnosti a účinnosti dne 1. prosince 2024.
    </p>
</template>

<script>
export default {};
</script>


<style scoped lang="scss">
@import "@/scss/abstracts/variables";

a {
    color: $purple;
}

h2 {
    text-align: center;
}
</style>
