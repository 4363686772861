<template>
  <div class="vertical-nav bg-main p-4 d-flex flex-column" id="sidebar">
    <!-- Head -->
    <div class="head">
      <div class="p-0 main_name d-flex justify-content-center">
        <router-link class="nav-link text-nowrap" to="/companies">
          {{ $t("titles.companyName") }}
        </router-link>
      </div>
      <div class="p-0 mt-3" v-if="!showAdmin">
        <SelectInstitution class="" />
      </div>
    </div>

    <!-- Content APP -->
    <div class="nav_content mt-4" v-if="!showAdmin">
      <div class="nav_item mt-3">
        <router-link v-if="$store.getters.institutions && $store.getters.institutions.length !== 0"
          class="nav-link text-nowrap" to="/companies">
          <icon icon="Search" class="search_icon"></icon>
          <span class="nav_text" :class="{ 'active': isCurrentRoute('/companies') }">{{ $t("navbar.search") }}</span>
        </router-link>
        <router-link v-else class="nav-link text-nowrap" to="/institution/none">
          <icon icon="Search" class="search_icon"></icon>
          <span class="nav_text" :class="{ 'active': isCurrentRoute('/companies') }">{{ $t("navbar.search") }}</span>
        </router-link>
      </div>
      <div class="nav_item mt-3" :class="{ 'active': isCurrentRoute('/profile') }">
        <router-link class="nav-link text-nowrap" to="/profile">
          <icon icon="User" class="user_icon"></icon>
          <span class="nav_text" :class="{ 'active': isCurrentRoute('/profile') }">{{ $t("navbar.myProfile") }}</span>
        </router-link>
      </div>
      <div v-if="$store.getters.isAdmin" class="nav_item mt-3"
        :class="{ 'active': isCurrentRoute('/institution/' + institutionId) }">
        <router-link :to="'/institution/' + institutionId" class="nav-link text-nowrap">
          <icon icon="Users" class="users_icon"></icon>
          <span class="nav_text" :class="{ 'active': isCurrentRoute('/institution/' + institutionId) }">{{
            $t("navbar.institution") }}</span>
        </router-link>
      </div>
    </div>

      <!-- Content Admin -->
      <div class="nav_content mt-4" v-if="showAdmin">
      <div class="nav_item mt-3">
        <router-link
          class="nav-link text-nowrap" to="/companies">
          <icon icon="Info" class="search_icon"></icon>
          <span class="nav_text">{{ $t("navbar.toApp") }}</span>
        </router-link>
      </div>
      <div class="nav_item mt-3">
        <router-link
          class="nav-link text-nowrap" to="/admin">
          <icon icon="Search" class="search_icon"></icon>
          <span class="nav_text" :class="{ 'active': isCurrentRoute('/admin') }">{{ $t("navbar.institutionSearch") }}</span>
        </router-link>
      </div>
      <div class="nav_item mt-3">
        <router-link
          class="nav-link text-nowrap" to="/admin/general-stats">
          <icon icon="Chart" class=""></icon>
          <span class="nav_text" :class="{'active': isCurrentRoute('/admin/general-stats')}">{{ $t("navbar.generalStats") }}</span>
        </router-link>
      </div>
      <div class="nav_item mt-3">
        <router-link
          class="nav-link text-nowrap" to="/admin/sales-stats">
          <icon icon="Chart" class=""></icon>
          <span class="nav_text" :class="{'active': isCurrentRoute('/admin/sales-stats')}">{{ $t("navbar.salesStats") }}</span>
        </router-link>
      </div>
      <div class="nav_item mt-3" :class="{ 'active': isCurrentRoute('/admin/institution/add') }">
        <router-link class="nav-link text-nowrap" to="/admin/institution/add">
          <icon icon="Plus" class="user_icon"></icon>
          <span class="nav_text" :class="{ 'active': isCurrentRoute('/admin/institution/add') }">{{ $t("navbar.institutionAdd") }}</span>
        </router-link>
      </div>
    </div>

    <!-- Lower content -->
    <div class="flex-grow-1"></div> <!-- Add flex-grow-1 class -->
    <div class="lower_content text-start">
      <div class="mb-4 logout text-center">
        <router-link class="nav-link text-nowrap d-flex align-items-center justify-content-center" to="/logout">
          <icon icon="Logout" class="logout_icon pe-2"></icon>
          <span>{{ $t("navbar.logout") }}</span>
        </router-link>
      </div>
      <div class="nav_item mb-4 d-flex align-items-center text-center justify-content-center">
        <span class="show_changelog" @click="showChangelog()">
          {{ $t("utils.version") }}: {{ $store.getters.version }}</span>
      </div>
    </div>

    <div class="d-inline d-md-none mt-4">
      <button class="btn btn_close close" @click="toggleMobileShow">Zavřít</button>
    </div>
  </div>

</template>

<script>
import SelectInstitution from "@/components/SelectInstitution.vue"
import Icon from '@/components/Icon.vue';
import ShowUpdates from "@/components/ShowUpdates.vue";

export default {
  components: {
    SelectInstitution,
    Icon,
    ShowUpdates
  },
  data() {
    return {
      institutionId: null,
      mobileShow: false,
    }
  },
  created() {
    // Get institution id from store
    this.institutionId = this.$store.getters.selectedInstitution;
  },
  computed: {
    user: function () {
      return this.$store.getters.user
    },
    showAdmin: function () {
      if (this.$route.meta.admin != null) {
        return this.$route.meta.admin === true;;
      }
      return false;
    },
  },
  methods: {
    // Sidebar toggle behavior
    toggleMobileShow() {
      this.mobileShow = false
      this.$emit('update-mobile-show', this.mobileShow);
    },
    isCurrentRoute(route) {
      return this.$route.path === route;
    },
    showChangelog() {
      this.$store.getters.user.seen_updates = false
    },
  }
}
</script>

<style scoped lang="scss">
@import "@/scss/abstracts/variables";
@import "@/scss/base/typography.scss";

.vertical-nav {
  min-height: 95vh;
  max-height: 100vh;
  position: fixed;
  margin: 20px;
  border-radius: 65px;
  top: 0;
  left: 0;
  box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.1);
  transition: all 0.4s;
  background: linear-gradient(180deg, $purple 26%, $light-purple 100%);
  color: $white;

  .main_name {
    color: $white;
    font-size: 40px;
    font-style: normal;
    text-shadow: 5px 5px 10px $black;
  }

  .nav_link {
    font-size: 20px;
    text-decoration: none;
    font-weight: 400;
    display: flex;
    align-items: center;
  }


  .nav_text {
    display: inline-block;

    &.active::after {
      content: '';
      display: block;
      width: 80%;
      padding-bottom: 5px;
      margin: 0 auto;
      border-bottom: 3px solid $white;

    }
  }

  .nav_item {
    font-size: 18px;
    text-decoration: none;

    &:hover .nav_text::after {
      content: '';
      display: block;
      width: 80%;
      padding-bottom: 5px;
      margin: 0 auto;
      border-bottom: 3px solid $white;
    }

    a {
      font-size: 18px;
      text-decoration: none;
      font-weight: 400;
      letter-spacing: 0.5px;
    }

    svg {
      width: 45px;
      height: 45px;
      border-radius: 20px;
      padding: 7px;
      stroke: $white;
      background-color: transparent;
      margin-right: 2px;
    }
  }

  .close {
    padding: 10px 40px;
    font-size: 20px;
    background-color: $grey;
    border-radius: 35px;
    color: $black;
  }
  .show_changelog:hover {
    cursor: pointer;
    text-decoration: underline;
  }
}

.logout {
  background-color: $dark-purple;
  border-radius: 65px;
  padding: 5px 20px;
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.3);
  letter-spacing: 0.5px;
  line-height: 64px;
  font-size: 18px;
  text-decoration: none;

  svg {
    width: 45px;
    height: 45px;
    border-radius: 20px;
    padding: 7px;
    stroke: $white;
    background-color: transparent;
    display: inline-block;
  }
}

.logout:hover {
  background-color: $black-purple;
}

/* for toggle behavior */

#sidebar.active {
  margin-left: -17rem;
}

#content.active {
  width: 100%;
  margin: 0;
}

@media (min-width: 1400px) and (max-width: 1700px) {
  .navbar {
    margin-right: 0px;
  }
}

@media (max-width: 768px) {
  .toggle {
    display: block;
  }

  #sidebar.active {
    margin-left: 0;
  }

  #content {
    width: 100%;
    margin: 0;
  }

  #content.active {
    margin-left: 17rem;
    width: calc(100% - 17rem);
  }
}

@media (min-width: 768px) {
  .toggle {
    display: none;
  }
}
</style>