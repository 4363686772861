<template>
    <div class="row justify-content-center align-items-center w-100">
        <div class="col-12 col-md-10 col-lg-8 col-xxl-6 inner">
            <div>
                <h1 class="text-center company_name">{{ $t("titles.companyName") }}</h1>
            </div>
            <div class="d-flex flex-wrap justify-content-between">
                <h2 class="login_title">{{ $t("login.login") }}</h2>
            </div>
            <div v-if="invalid" class="alert alert-danger mt-3 mb-1" role="alert">
                {{ $t("login.invalid") }}
            </div>
            <div class="d-flex input_group mt-3" :class="{ active: activeInput === 'username' || username }">
                <label for="email" class="my-auto mx-4">
                    {{ $t("login.email") }}:
                </label>
                <input type="text" id="email" ref="username" class="" v-model="username"
                    :placeholder="$t('login.yourUsername')" @focus="activateInput('username')"
                    @blur="deactivateInput()">
            </div>
            <div class="d-flex input_group mt-3" :class="{ active: activeInput === 'password' || password }">
                <label for="password" class="my-auto mx-4">{{ $t("login.password") }}:</label>
                <input type="password" id="password" class="" ref="password" v-model="password"
                    :placeholder="$t('login.yourPassword')" @focus="activateInput('password')"
                    @blur="deactivateInput()">
            </div>
        </div>
        <div class="text-center">
            <button type="submit" class="btn btn_submit" @click="login()">
                <span>{{ $t("login.button") }}</span>
            </button>
        </div>
    </div>
</template>

<script>
import store from "@/store";

export default {
    props: {
        data: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            username: null,
            password: null,
            invalid: false,
            token: this.$route.params.token,
            activeInput: false
        }
    },
    methods: {
        login: function () {
            this.$store.getters.api.post("/auth/login",
                { "username": this.username, "password": this.password }
            ).then(
                (response) => {
                    const data = response.data;
                    if (data.error_code == 100) {
                        this.invalid = true;
                        this.$refs.password.setCustomValidity(this.$t("login.invalid"));
                        this.$refs.username.setCustomValidity(this.$t("login.invalid"));
                        this.$refs.password.reportValidity();
                        this.$refs.username.reportValidity();
                    }
                    else {
                        const userId = data.user.id;
                        store.commit("saveUserProfile", response.data.user);
                        this.addUserToInstitution(userId);
                    }
                }
            )
        },
        addUserToInstitution: function (userId) {
            this.$store.getters.api.post(`/institution/user/${this.token}/add`, { user_id: userId })
                .then((response) => {
                    this.$router.push({ name: "login" });

                })
        },
        activateInput(inputId) {
            this.activeInput = inputId;
        },
        deactivateInput() {
            // Clear the active input ID
            this.activeInput = null
        }
    },
}
</script>

<style scoped lang="scss">
@import "@/scss/abstracts/variables";
@import "@/scss/base/typography.scss";

.inner {
    background: radial-gradient(circle, $light-purple 6%, $purple 100%);
    border-radius: 50px;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.5);
    padding: 45px 50px 90px 50px;
    margin: 0 0 20px 0;
    font-family: 'Rubik One Regular';
}

.company_name {
    font-family: "Outfit";
    font-size: 66px;
    text-shadow: 5px 5px 10px $black;
    color: $white;
    font-weight: 800;
}

.login_title {
    color: $white;
    font-size: 30px;
}

.input_group {
    background-color: $white;
    border-radius: 50px;

    label {
        color: $black;
        width: 10%;
        font-size: 20px;
    }

    input {
        background-color: $white;
        border: none;
        width: 90%;
        outline: none;
        border-radius: 0 50px 50px 0;
        padding: 20px;
        font-size: 20px;
    }
}

.input_group.active label {
    transition: all 0.2s ease-in-out;
    display: none
}

.input_group.active {
    background-color: $white;
    padding: 0
}

.input_group.active input {
    width: 100%;
    opacity: 1;
    padding: 20px 20px;
    border-radius: 50px;
    outline: none;
    border: none;
}
</style>