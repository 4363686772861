<template>
    <div class="container mt-4">
        <div class="row justify-content-center align-items-center g-3 mb-3">
            <div class="col-auto flex-grow-1 flex-basis">
                <div class="square-block">
                    <div class="label">Počet platících klientů:</div>
                    <div class="value">{{ totalPaidInstitutions  }}</div>
                </div>
            </div>
            <div class="col-auto flex-grow-1 flex-basis">
                <div class="square-block">
                    <div class="label">Celkový počet uživatelů:</div>
                    <div class="value">{{ totalPaidUsers }}</div>
                </div>
            </div>
            <div class="col-auto flex-grow-1 flex-basis">
                <div class="square-block text-center">
                    <div class="label">Zaplacená částka:</div>
                    <div class="value">{{ formattedAmount }}</div>
                </div>
            </div>
        </div>
        <LineChart :chart-data="chartData"/>
    </div>
</template>

<script>
    import LineChart from '../components/LineChart.vue';

    export default {
        components: {
            LineChart
        },
        data() {
            return {
                totalPaidInstitutions: 0,
                totalPaidUsers: 0,
                totalPaidAmount: 0,
                chartData: []
            };
        },
        created() {
            this.loadData();
        },
        methods: {
            loadData() {
                this.$store.getters.api.get("/stats/general").then(response => {
                    this.totalPaidInstitutions = response.data.total_paid_institutions;
                    this.totalPaidUsers = response.data.total_paid_users;
                    this.totalPaidAmount = response.data.total_paid_amount;

                    this.chartData = response.data.chart_data.map(item => ({
                        date: item.date,
                        paidUsers: item.total_paid_users
                    }));
                }).catch(error => {
                    console.error("Error loading data:", error);
                });
            }
        },
        computed: {
            formattedAmount() {
                return (
                    new Intl.NumberFormat("cs-CZ", {
                    style: "decimal",
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                    }).format(this.totalPaidAmount) + " Kč"
                );
            },
        },
    }
</script>

<style scoped>
    .square-block {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
        padding: 10px;
        width: 100%;
        background-color: #FFFFFF;
        height: 150px;
        border-radius: 10px;
    }
    .label {
        color: #7a7a7a;
        font-weight: bold;
        margin-bottom: 5px;
    }
    .value {
        font-size: 30px;        
        font-weight: bold;
        color: black;
        white-space: nowrap;
    }
</style>